<template>
  <!-- 更改密码 -->
  <el-card class="card_right_bottom">
    <!-- 标题 -->
    <!-- 更改密码 -->
    <div class="card_right_bottom_title">
      {{ $t("label.userinfo.updatepwd") }}
    </div>
    <!-- 第一个盒子 -->
    <div class="box1">
      <div class="item_space">
        <!-- 密码信息 -->
        <div class="item_space_title_wrappe">{{ $t("label.pwinfo") }}</div>
        <div class="item_space_body_wrappe">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="300px"
            class="ruleForm"
            label-position="right"
          >
            <!-- 表单模块 -->
            <div class="model_wrapper">
              <!-- 左侧模块 -->
              <div class="left_model">
                <!-- 模块内容包 -->
                <div class="left_model_content">
                  <!-- 旧密码 -->
                  <el-form-item
                    :label="$t('label.oldpw')"
                    prop="oldPassword"
                    size="mini"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.oldPassword"
                      style="width: 160px"
                    ></el-input>
                  </el-form-item>
                  <!-- 新密码 -->
                  <el-form-item
                    :label="$t('label.newpw')"
                    prop="newPassword"
                    size="mini"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.newPassword"
                      @input="changNewPassword"
                      style="width: 160px"
                    ></el-input>
                  </el-form-item>
                  <!-- 确认新密码  -->
                  <el-form-item
                    :label="$t('label.config.pw')"
                    prop="repassword"
                    size="mini"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.repassword"
                      style="width: 160px"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <!-- 右侧模块 -->
              <div class="right_model_content_left">
                <div class="content">
                  <!-- 请为{{ user }}输入密码，请确保包含至少 -->
                  <div>{{ $t("label.pw.text", { user: user }) }}：</div>
                  <!-- 组织密码策略 -->

                  <el-radio
                    v-for="(item, index) in rulesPassword"
                    :key="rulesPassword[index].name"
                    v-model="rulesPassword[index].check"
                    :label="rulesPassword[index].label"
                    >{{ rulesPassword[index].name }}</el-radio
                  >
                </div>
              </div>
            </div>
          </el-form>
          <!-- 按钮组 -->
          <div style="text-align: center">
            <!-- 保存 -->
            <el-button
              type="primary"
              size="mini"
              @click="submitForm('ruleForm')"
              >{{ $t("component.telerecord.button.save") }}</el-button
            >
            <!-- 取消 -->
            <el-button size="mini" @click="resetForm('ruleForm')">{{
              $t("label.emailsync.button.cancel")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import {
  userPwModify,
  getPasswordPolicy,
} from "@/views/profileSetting/profileSettingApi.js";
import md5 from "js-md5"; // md5加密
export default {
  data() {
    // 确认新密码验证规则
    // 两次输入密码不一致!
    let that = this;
    let repassword = (rule, value, callback) => {
      if (value !== this.ruleForm.newPassword) {
        callback(new Error(that.$t("label.pw.error.text")));
      } else {
        callback();
      }
    };
    return {
      minPswLength: 5, // 组织密码最小长度
      // 组织密码策略
      rulesPassword: [
        { name: this.$i18n.t("label.pw.str"), check: "", label: "0" },
      ],
      // 用户名  5个字符
      user: localStorage.getItem("username"),
      // 密码对象
      ruleForm: {
        repassword: "", // 确认新密码
        newPassword: "",
        oldPassword: "",
      },
      // 验证规则
      rules: {
        oldPassword: [
          {
            required: true,
            message: this.$i18n.t("label.pw.old"),
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: this.$i18n.t("label.pw.new"),
            trigger: "blur",
          },
        ],
        repassword: [
          {
            required: true,
            message: this.$i18n.t("label.pw.config"),
            trigger: "blur",
          },
          { validator: repassword, trigger: "blur" },
        ],
      },
      // 验证规则结束
    };
  },
  created() {
    this.getPasswordPolicy();
  },
  methods: {
    // 获取密码策略
    getPasswordPolicy() {
      getPasswordPolicy().then((res) => {
        if (res.result) {
          // 个字符
          this.minPswLength = res.data.minPswLength;
          this.rulesPassword[0].name =
            res.data.minPswLength + ' ' +
            this.$i18n.t("message.duplication.label.nletters");
          switch (res.data.pswComplexReq) {
            case "1":
              // 1-必须混合使用字母和数字
              this.rulesPassword = this.rulesPassword.concat([
                {
                  name: this.$i18n.t("label.pw.letter"),
                  check: "",
                  label: "1",
                },
                { name: this.$i18n.t("label.number"), check: "", label: "2" },
              ]);
              break;
            case "2":
              // 2-必须包含大小写字母及数字
              this.rulesPassword = this.rulesPassword.concat([
                { name: this.$i18n.t("label.number"), check: "", label: "2" },
                {
                  name: this.$i18n.t("label.pw.big.letter"),
                  check: "",
                  label: "3",
                },
                {
                  name: this.$i18n.t("label.pw.small.letter"),
                  check: "",
                  label: "4",
                },
              ]);
              break;
            case "3":
              // 3-必须包含大小写字母数字及符号
              this.rulesPassword = this.rulesPassword.concat([
                { name: this.$i18n.t("label.number"), check: "", label: "2" },
                {
                  name: this.$i18n.t("label.pw.big.letter"),
                  check: "",
                  label: "3",
                },
                {
                  name: this.$i18n.t("label.pw.small.letter"),
                  check: "",
                  label: "4",
                },
                { name: this.$i18n.t("label.pw.symol"), check: "", label: "5" },
              ]);
              break;
          }
        }
      });
    },
    // 匹配新密码
    changNewPassword(val) {
      // 验证新密码不能含有中文和空格
      let reg = /[\u4e00-\u9fa5/\s+/]/gi;
      if (reg.test(this.ruleForm.newPassword)) {
        this.ruleForm.newPassword = "";
        this.ruleForm.repassword = "";
        // 新密码不能包含中文和空格
        this.$message.error(this.$i18n.t("label.new.password.no.indexof"));
        return false;
      }
      // 标识是否全匹配成功
      let flag = 0;

      this.rulesPassword.forEach((item, index) => {
        if (item.label === "0") {
          // 最小长度
          if (val.length >= this.minPswLength) {
            this.rulesPassword[0].check = "0";
            flag++;
          } else {
            this.rulesPassword[0].check = "";
          }
        }
        if (item.label === "1") {
          // 包含字母
          let strReg = /[A-Za-z]/;
          if (strReg.test(val)) {
            this.rulesPassword[index].check = "1";
            flag++;
          } else {
            this.rulesPassword[index].check = "";
          }
        }
        if (item.label === "2") {
          // 包含数字
          let strReg2 = /\d/;
          if (strReg2.test(val)) {
            this.rulesPassword[index].check = "2";
            flag++;
          } else {
            this.rulesPassword[index].check = "";
          }
        }
        if (item.label === "3") {
          // 包含大写字母
          let strReg3 = /[A-Z]/;
          if (strReg3.test(val)) {
            this.rulesPassword[index].check = "3";
            flag++;
          } else {
            this.rulesPassword[index].check = "";
          }
        }
        if (item.label === "4") {
          // 包含小写字母
          let strReg4 = /[a-z]/;
          if (strReg4.test(val)) {
            this.rulesPassword[index].check = "4";
            flag++;
          } else {
            this.rulesPassword[index].check = "";
          }
        }
        if (item.label === "5") {
          // 符号
          let strReg5 = /[^(A-Za-z0-9)]/;
          if (strReg5.test(val)) {
            this.rulesPassword[index].check = "5";
            flag++;
          } else {
            this.rulesPassword[index].check = "";
          }
        }
      });
      return flag === this.rulesPassword.length;
    },
    // 保存密码
    submitForm(formName) {
      let flag = this.changNewPassword(this.ruleForm.newPassword);
      if (flag) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              newPassword: md5(this.ruleForm.newPassword),
              oldPassword: md5(this.ruleForm.oldPassword),
            };
            userPwModify(params).then((res) => {
              if (res.data.result === "true") {
                this.$message.success(res.data.resultInfo);
              } else {
                this.$message.error(res.data.resultInfo);
              }
            });
          } else {
            return false;
          }
        });
      } else {
        // 请输入正确的新密码
        this.$message.error(this.$i18n.t("label.input.new.password"));
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      // 清空右侧密码策略
      this.rulesPassword.forEach((item) => {
        item.check = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style/comment";
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 28px;
}
.right_model_content_left {
  flex: 1;
  .content {
    padding-left: 100px;
    ::v-deep .el-radio {
      display: block;
      margin-top: 6px;
      margin-left: 6px;
    }
  }
}
</style>